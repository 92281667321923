<template>
  <main class="container pb-16 pb-lg-32 px-lg-32">
    <div class="mb-8" v-if="schedule">
      <ScheduleInfo :schedule="schedule" />
    </div>

    <div class="bg-white rounded-8" v-if="material">
      <div
        class="d-flex align-items-center justify px-24 py-16 border-bottom"
        v-if="material.file"
      >
        <h2 class="fs-20 me-auto mb-0">Materi</h2>
        <a
          class="btn btn-sm btn-primary d-inline-flex align-items-center"
          target="_blank"
          v-if="material.file"
          :href="material.fileURL"
        >
          <CloudDownloadIcon class="me-8" />
          Download
        </a>
      </div>

      <RichTextContent
        class="p-24"
        v-if="material.content"
        :content="material.content"
      />
    </div>
  </main>
</template>

<script>
import Schedule from '../../../../store/models/Schedule';
import Material from '../../../../store/models/Material';

import RichTextContent from '../../../../components/content/RichTextContent.vue';
import ScheduleInfo from '../../../../components/student/schedule/ScheduleInfo.vue';

import CloudDownloadIcon from 'bootstrap-icons/icons/cloud-download.svg';

export default {
  components: {
    RichTextContent,
    ScheduleInfo,
    CloudDownloadIcon,
  },

  computed: {
    schedule() {
      return Schedule.query()
        .withAllRecursive()
        .find(this.$route.params.schedule_id);
    },

    material() {
      return Material.query().withAll().find(this.$route.params.material_id);
    },
  },

  metaInfo() {
    return {
      title: this.material && this.material.title,
    };
  },

  async beforeMount() {
    if (!this.schedule) {
      await this.$store.dispatch('schedules/maybeFetch', {
        filter: { id: this.$route.params.schedule_id },
      });
    }

    this.$store.dispatch('materials/fetch', {
      filter: { id: this.$route.params.material_id },
    });

    this.$store.commit('info/setBreadcrumb', [
      { path: '/guru/jadwal', label: 'Jadwal' },
      {
        path: this.schedule.scheduleRoutePath,
        label: this.schedule.subject.name,
      },
      { path: this.schedule.materialsRoutePath, label: 'Materi' },
    ]);
  },
};
</script>
